<template>
    <div class='relative md:w-full md:h-screen' id='m-screen'>
        <AboutComponent class='absolute top-0 left-0 z-50' />
        <swiper
            :effect="'fade'"
            :modules='modules'
            :slides-per-view='1'
            @swiper='onSwiper'
            @slideChangeTransitionStart='onSlideTransitionStart'
            @slideChangeTransitionEnd='onSlideChange'
            ref='swiperInstance'
            :loop='true'
            class='relative md:w-full md:h-screen '
        >
            <swiper-slide
                v-for='(slideContent, index) in slides'
                :key='index'
                @click='nextSlide'
                class='flex justify-center items-center'
            >
                <div class='w-full bg-white'>
                    <div id='textEl'
                         :class="['px-4 font-clarendon bottom-0 md:bottom-auto md:top-0 inverted-text leading-none md:leading-tight tracking-normal md:tracking-tighter', {'invisible': !isImageLoaded[index]}]">
                        {{ slideContent.text }}
                    </div>
                    <div class='m-container' id='m-container'>
                        <img
                            :src='slideContent.img'
                            alt='Image'
                            class='min-h-screen object-cover w-full h-full md:w-auto md:h-auto md:max-w-full md:max-h-full md:object-center'
                            @load='onImageLoad(index)'
                        />
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import AboutComponent from '@/components/AboutComponent.vue';

import 'swiper/css';
import 'swiper/css/effect-fade';

export default {
    components: {
        AboutComponent,
        Swiper,
        SwiperSlide
    },
    setup() {
        const swiper = ref(null);
        const initialTextColor = ref([]);
        const isImageLoaded = ref([]);
        const activeSlideIndex = ref(0);
        let autoSlideInterval = null;

        const resizeText = () => {
            const container = document.querySelector('#m-screen');
            const textEl = document.querySelector('.swiper-slide-active #textEl');
            if (!container || !textEl) return;

            let minFontSize = 30;
            let maxFontSize = 260;
            let fontSize = maxFontSize;

            textEl.style.fontSize = fontSize + 'px';

            while (minFontSize <= maxFontSize) {
                fontSize = Math.floor((minFontSize + maxFontSize) / 2);
                textEl.style.fontSize = fontSize + 'px';

                if (textEl.offsetWidth > container.offsetWidth || textEl.offsetHeight > container.offsetHeight) {
                    maxFontSize = fontSize - 4;
                } else {
                    minFontSize = fontSize + 4;
                }
            }

            textEl.style.fontSize = maxFontSize + 'px';
            textEl.classList.remove('invisible');
        };

        const onSlideChange = () => {
            if (swiper.value) {
                activeSlideIndex.value = swiper.value.realIndex;
                nextTick(() => {
                    resetTimer();
                    applyTextTransition(activeSlideIndex.value);
                    setTimeout(resizeText, 0);
                });
            }
        };

        const onSlideTransitionStart = () => {
            const textEl = document.querySelector('.swiper-slide-active #textEl');
            if (textEl) textEl.classList.add('invisible');
        };

        const onSwiper = (swiperInstance) => {
            swiper.value = swiperInstance;
        };

        const nextSlide = () => {
            if (swiper.value) swiper.value.slideNext();
        };

        const prevSlide = () => {
            if (swiper.value) {
                swiper.value.slidePrev();
            }
        };

        const slides = [
            { 'img': 'img/1_hrff22.jpg', 'text': 'Human Rights Film Festival. Visual Identity, 2022', 'color': '#000000' },
            { 'img': 'img/2_buechi.jpg', 'text': 'Noémi Büchi. Record Cover, 2024', 'color': '#000000' },
            {
                'img': 'img/3_grossmuenster.jpg',
                'text': 'Geschichte der evangelisch-reformierten Kirchgemeinde zum Grossmünster Zürich. Book, 2021',
                'color': '#000000'
            },
            { 'img': 'img/4_sommeratelier.jpg', 'text': 'ZHdK Sommeratelier Kunst & Design. Poster & Booklet, 2019', 'color': '#000000' },
            { 'img': 'img/5_landesmuseum.jpg', 'text': 'Landesmuseum Zürich. Teaching materials, 2012–17', 'color': '#000000' },
            { 'img': 'img/6_tape.jpg', 'text': 'Tape Now #1. On-site Cover Production, 2020', 'color': '#000000' },
            { 'img': 'img/7_bama.jpg', 'text': 'Zurich University of the Arts. Bachelor Design Manual, 2017', 'color': '#FFFFFF' }
        ];

        slides.forEach((slide) => {
            initialTextColor.value.push(slide.color);
            isImageLoaded.value.push(false);
        });

        const onImageLoad = (index) => {
            isImageLoaded.value[index] = true;
            resizeText();
        };

        const applyTextTransition = (index) => {
            const slidesElements = document.querySelectorAll('.swiper-slide');
            slidesElements.forEach((slide) => {
                const textEl = slide.querySelector('#textEl');
                const isMatchingRealIndex = slide.getAttribute('data-swiper-slide-index') === index.toString(10);
                if (textEl) {
                    if (isMatchingRealIndex) {
                        textEl.classList.remove('fade-out');
                        textEl.classList.add('fade-in');
                        textEl.style.color = slides[swiper.value.realIndex].color;
                        setTimeout(() => {
                            textEl.classList.remove('fade-in');
                            textEl.classList.add('fade-out');
                            textEl.style.color = '';
                        }, 0);
                    } else {
                        textEl.classList.remove('fade-in', 'fade-out');
                    }
                }
            });
        };

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') nextSlide();
            else if (event.key === 'ArrowLeft') prevSlide();
        };

        const startAutoSlide = () => {
            autoSlideInterval = setInterval(nextSlide, 8000);
        };

        const stopAutoSlide = () => {
            if (autoSlideInterval) clearInterval(autoSlideInterval);
        };

        const resetTimer = () => {
            stopAutoSlide();
            startAutoSlide();
        };

        const applyInitialTransition = () => {
            const firstSlideTextEl = document.querySelector('.swiper-slide-active #textEl');
            if (firstSlideTextEl) {
                setTimeout(() => {
                    firstSlideTextEl.style.color = slides[0].color;
                    firstSlideTextEl.classList.remove('fade-in');
                    firstSlideTextEl.classList.add('fade-out');
                    firstSlideTextEl.style.color = '';
                }, 0);
            }
        };

        onMounted(() => {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('load', resizeText);
            window.addEventListener('resize', debounce(resizeText, 200));
            startAutoSlide();
            nextTick(() => {
                activeSlideIndex.value = swiper.value?.realIndex || 0;
                applyInitialTransition();
                setTimeout(resizeText, 0);
            });
        });

        onUnmounted(() => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('load', resizeText);
            window.removeEventListener('resize', resizeText);
            stopAutoSlide();
        });

        function debounce(func, wait) {
            let timeout;
            return function(...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        }

        return {
            swiper,
            slides,
            initialTextColor,
            isImageLoaded,
            onImageLoad,
            nextSlide,
            onSwiper,
            onSlideChange,
            onSlideTransitionStart,
            activeSlideIndex,
            modules: [EffectFade]
        };
    }
};
</script>

<style scoped>
.m-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.inverted-text {
    white-space: pre-wrap;
    text-align: left;
    vertical-align: top;
    position: absolute;
    z-index: 10;
}


.invisible {
    opacity: 0 !important;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 0 !important;
    transition: opacity 3s ease-in-out, color 1s ease-in-out;
    opacity: 1 !important;
}

.fade-out {
    transition: opacity 5s ease-in-out, color 1s ease-in-out;
    opacity: 1 !important;
    mix-blend-mode: difference;
    color: white;
}
</style>